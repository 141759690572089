import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ErrorPage from "./ErrorPage";
import Paypal from "./Paypal";
import Footer from "./Footer";
import "./App.css";


function App() {
  return (
    <React.Fragment>
        <Router>
          <Routes>
          <Route path="/:id" element={<Paypal />} />
          <Route path="/errorPage" element={<ErrorPage />} />
        </Routes>
              
        </Router>
    </React.Fragment>
  );
}
export default App;

import React from 'react'
import error from './Assets/Images/something-went-wrong-free-vector.jpg';
function Falsefile() {
  return (
    <div className='Falsefile d-flex justify-content-center'>
    <img src={error} alt='errormsg' height='500px'/>
    </div>
  )
}

export default Falsefile

import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { Form } from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
// import { FaRegEdit, FaEye, FaSearch } from "react-icons/fa";
import { MailOutlined, PhoneOutlined } from "@ant-design/icons";
import { Modal, Navbar, Button, Container } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate, NavLink } from "react-router-dom";
import logo from "../src/Assets/Images/logo.png";
import flogo from "../src/Assets/Images/favicon.png";
import Footer from "./Footer";
import { Layout, Row, Col } from "antd";

const App = () => {
  const isRendered = useRef(false);
  const [currencyCode, setCurrencyCode] = useState("");
  const [currencyCode1, setCurrencyCode1] = useState("EUR");

  const [data1, setData] = useState([]);
  const [cur001, setCur001] = useState([]);
  const [show, setShow] = useState(false);
  const [modalData, setModalData] = useState(null);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { Footer } = Layout;

  const [orderId, setOrderId] = useState("");
  const [records, setRecords] = useState([]);
  const [users, setUsers] = useState(null);
  const [fullName, setFullName] = useState("");
  const [clientId, setClientId] = useState("");
  const [secretId, setSecretId] = useState("");
  const [Currency, setCurrency] = useState("");
  const [Amount, setAmount] = useState("");
  const [ProductName, setProductName] = useState("");
  const [Qty, setQty] = useState("");
  const [UserId, setUserId] = useState("");
  const navigate = useNavigate();

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const segment = window.location.pathname.split("/");
  //       const formData = new FormData();
  //       formData.append("id", segment[1]);

  //       const response = await axios.post(
  //         `https://api.mtmpay.in/paypal-get-users-info`,
  //         formData,
  //         {
  //           headers: {
  //             "Content-Type": "multipart/form-data",
  //           },
  //         }
  //       );

  //       const { status, records, user } = response.data;

  //       if (status !== true) {
  //         localStorage.clear();
  //         navigate("/errorPage");

  //         return;
  //       }

  //       const mappedRecordsInfo = {
  //         orderId: records.orderid,
  //         UserId: records.userid,
  //         Currency: records.currency,
  //         Amount: records.amount,
  //         ProductName: records.product_name,
  //         Qty: records.qty,
  //       };
  //       setRecords(mappedRecordsInfo);
  //       setOrderId(mappedRecordsInfo.orderId);
  //       setUserId(mappedRecordsInfo.UserId);
  //       setCurrency(mappedRecordsInfo.Currency);
  //       setAmount(mappedRecordsInfo.Amount);
  //       // alert(mappedRecordsInfo.Amount);
  //       setProductName(mappedRecordsInfo.ProductName);
  //       setQty(mappedRecordsInfo.Qty);
  //       console.log("Order ID:", mappedRecordsInfo.orderId);

  //       handleChangeCurrency(mappedRecordsInfo.Currency);

  //       setFormData((prevFormData) => ({
  //         ...prevFormData,
  //         amount: mappedRecordsInfo.Amount,
  //       }));
  //       setFormData((prevFormData) => ({
  //         ...prevFormData,
  //         product_name: mappedRecordsInfo.ProductName,
  //       }));
  //       setFormData((prevFormData) => ({
  //         ...prevFormData,
  //         quantity: mappedRecordsInfo.Qty,
  //       }));

  //       const mappedUserInfo = {
  //         fullName: user.full_name,
  //         clientId: user.paypal_client_id,
  //         secretId: user.paypal_secret_id,
  //       };
  //       setUsers(mappedUserInfo);
  //       setFullName(mappedUserInfo.fullName);
  //       setClientId(mappedUserInfo.clientId);
  //       setSecretId(mappedUserInfo.secretId);

  //       console.warn("client-id", user.paypal_client_id);
  //       console.warn("currency", records.currency);
  //       const script = document.createElement("script");
  //       script.src = `https://www.paypal.com/sdk/js?client-id=${user.paypal_client_id}&currency=${records.currency}`;
  //       script.addEventListener("load", () => setLoaded(true));
  //       document.body.appendChild(script);

  //       // Clean up the script tag
  //       return () => {
  //         document.body.removeChild(script);
  //       };

  //       //   console.warn("FullNAme:", mappedUserInfo.fullName);
  //       // const script = document.createElement("script");
  //       // script.src = `https://www.paypal.com/sdk/js?client-id=EE0Ml6QoOSL3s6wrlMADKx7CRdGL6IV7iFGuEkaWd917MnqNKcF3H_0m6Zzfyj-dSQGR87lRqPjpqdgP`;
  //       // script.setAttribute(
  //       //   "data-partner-attribution-id",
  //       //   "MTMPaymentServicesPvtLtd_SI"
  //       // );
  //       // script.async = true;
  //       // document.body.appendChild(script);

  //       // script.src = `https://www.paypal.com/sdk/js?client-id=EE0Ml6QoOSL3s6wrlMADKx7CRdGL6IV7iFGuEkaWd917MnqNKcF3H_0m6Zzfyj-dSQGR87lRqPjpqdgP&currency=PHP`;
  //       // script.setAttribute(
  //       //   "data-partner-attribution-id",
  //       //   "MTMPaymentServicesPvtLtd_SI"
  //       // );
  //       // script.async = true;
  //       // document.body.appendChild(script);
  //       // const script = document.createElement("script");
  //       // script.src =
  //       //   "https://www.paypal.com/sdk/js?client-id=AeZwDTKHrIGVS5o0Wsp-wUqDzMl-O4pjrikdhKFq3PNCpJMYs_vVAvd9DueeQ92nRT8ybresK8A8CC-A&currency=PHP";
  //       // script.setAttribute(
  //       //   "data-partner-attribution-id",
  //       //   "MTMPaymentServicesPvtLtd_SI"
  //       // );
  //       // script.async = true;
  //       // document.body.appendChild(script);
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //     }
  //   };

  //   fetchData(); // Call fetchData function when the component mounts
  // }, [navigate]);
  const [loaded, setLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const segment = window.location.pathname.split("/");
        const formData = new FormData();
        formData.append("id", segment[1]);

        const response = await axios.post(
          `https://api.mtmpay.in/paypal-get-users-info`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        const { status, records, user } = response.data;

        if (status !== true) {
          localStorage.clear();
          navigate("/errorPage");

          return;
        }

        const mappedRecordsInfo = {
          orderId: records.orderid,
          UserId: records.userid,
          Currency: records.currency,
          Amount: records.amount,
          ProductName: records.product_name,
          Qty: records.qty,
        };
        setRecords(mappedRecordsInfo);
        setOrderId(mappedRecordsInfo.orderId);
        setUserId(mappedRecordsInfo.UserId);
        setCurrency(mappedRecordsInfo.Currency);
        setAmount(mappedRecordsInfo.Amount);
        setProductName(mappedRecordsInfo.ProductName);
        setQty(mappedRecordsInfo.Qty);
        console.log("Order ID:", mappedRecordsInfo.orderId);

        handleChangeCurrency(mappedRecordsInfo.Currency);

        setFormData((prevFormData) => ({
          ...prevFormData,
          amount: mappedRecordsInfo.Amount,
        }));
        setFormData((prevFormData) => ({
          ...prevFormData,
          product_name: mappedRecordsInfo.ProductName,
        }));
        setFormData((prevFormData) => ({
          ...prevFormData,
          quantity: mappedRecordsInfo.Qty,
        }));

        const mappedUserInfo = {
          fullName: user.full_name,
          clientId: user.paypal_client_id,
          secretId: user.paypal_secret_id,
        };
        setUsers(mappedUserInfo);
        setFullName(mappedUserInfo.fullName);
        setClientId(mappedUserInfo.clientId);
        setSecretId(mappedUserInfo.secretId);

        console.warn("client-id", user.paypal_client_id);
        console.warn("currency", records.currency);

        // Check if a PayPal script is already present, if so, remove it
        const existingScript = document.querySelector(
          'script[src^="https://www.paypal.com/sdk/js"]'
        );
        if (existingScript) {
          document.body.removeChild(existingScript);
        }
        setTimeout(() => {
          const script = document.createElement("script");
          script.src = `https://www.paypal.com/sdk/js?client-id=${user.paypal_client_id}&currency=${records.currency}`;
          script.addEventListener("load", () => setLoaded(true));
          document.body.appendChild(script);
        }, 10000);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData(); // Call fetchData function when the component mounts

    // Clean up the script tag
    return () => {
      const existingScript = document.querySelector(
        'script[src^="https://www.paypal.com/sdk/js"]'
      );
      if (existingScript) {
        document.body.removeChild(existingScript);
      }
    };
  }, [navigate]);

  useEffect(() => {
    // const script = document.createElement("script");
    // script.src =
    //   "https://www.paypal.com/sdk/js?client-id=AeZwDTKHrIGVS5o0Wsp-wUqDzMl-O4pjrikdhKFq3PNCpJMYs_vVAvd9DueeQ92nRT8ybresK8A8CC-A&currency=PHP";
    // script.addEventListener("load", () => setLoaded(true));
    // document.body.appendChild(script);
    // // Clean up the script tag
    // return () => {
    //   document.body.removeChild(script);
    // };
  }, []);

  const Submit = async (paypal_id) => {
    console.warn(paypal_id);
    try {
      const url = `https://pnode.mtmpay.in/order-checkstatus`;
      const payload = {
        orderid: paypal_id,
      };
      const response = await axios.post(url, payload);
      setModalData(response.data);
      console.warn("response", response.data);
    } catch (error) {
      console.error("Error fetching events:", error);
    }
  };

  const [formData, setFormData] = useState({
    amount: "",
    product_name: "",
    quantity: "",
  });

  const handleChangeAmount = (e) => {
    const { id, value, name } = e.target;

    // alert(value);
    // setFormData(prevFormData => ({
    //     ...prevFormData,
    //     [id]: value
    // }));
  };

  const handleChangeCurrency = (event) => {
    setCurrencyCode(event);
    // alert(event);
    var inputBox = document.getElementById("cur01");

    // Set the value of the input box
    inputBox.value = event; // Replace 'Your desired value' with the value you want to set
    const newCurrencyCode = event;
    setCurrencyCode1(newCurrencyCode);
    setCur001(event);
    // alert(newCurrencyCode);

    // const script = document.createElement("script");
    // script.src = `https://www.paypal.com/sdk/js?client-id=${clientId}&currency=${newCurrencyCode}`;
    // script.async = true;

    // // Load script and add to the document body
    // document.body.appendChild(script);

    // Clean up function to remove the script when component unmounts or currency code changes
    // return () => {
    //   document.body.removeChild(script);
    // };
  };

  useEffect(() => {
    // alert("opppp");
    // Create script element for PayPal SDK
    // const script = document.createElement('script');
    // script.src = `https://www.paypal.com/sdk/js?client-id=YOUR_CLIENT_ID&currency=${currencyCode1}`;
    // script.async = true;
    // // Load script and add to the document body
    // document.body.appendChild(script);
    // // Clean up function to remove the script when component unmounts or currency code changes
    // return () => {
    //     document.body.removeChild(script);
    // };
  }, [currencyCode1]); // useEffect will re-run whenever currencyCode changes

  const [eorderData, SetorderData] = useState("");
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
      // alert(currencyCode);
      const renderPayPalButtons = () => {
        const orderData = {
          currency_code: currencyCode,
          amount: formData.amount,
          product_name: formData.product_name,
          quantity: formData.quantity,
          full_name: fullName,
          userid: UserId,
        };
        // alert(Amount);
        if (
          currencyCode === "" ||
          Amount === "" ||
          formData.product_name === "" ||
          formData.quantity === ""
        ) {
          return;
        }
        localStorage.setItem("currency_code", currencyCode);
        localStorage.setItem("amount", formData.amount);
        localStorage.setItem("product_name", formData.product_name);
        localStorage.setItem("quantity", formData.quantity);
        localStorage.setItem("full_name", fullName);
        localStorage.setItem("userid", UserId);
        // SetorderData(orderData);

        if (isRendered.current) return;

        window.paypal
          .Buttons({
            createOrder: async (data, actions) => {
              const currency_code = localStorage.getItem("currency_code");
              const Amounts = localStorage.getItem("amount");
              const product_name = localStorage.getItem("product_name");
              const quantity = localStorage.getItem("quantity");
              const full_name = localStorage.getItem("full_name");
              const userid = localStorage.getItem("userid");
              const orderData = {
                currency_code: currency_code,
                amount: Amounts,
                product_name: product_name,
                quantity: quantity,
                full_name: full_name,
                userid: userid,
              };
              console.warn("formData", orderData);
              // alert(orderData)
              try {
                const response = await axios.post(
                  "https://pnode.mtmpay.in/api/orders",
                  orderData
                );
                return response.data.id; // Return the order ID
              } catch (error) {
                console.error("Create Order Error:", error);
                throw new Error("Failed to create order");
              }
            },
            onApprove: async (data, actions) => {
              try {
                const response = await axios.post(
                  `https://pnode.mtmpay.in/api/orders/${data.orderID}/capture/${UserId}/${fullName}`
                );
                console.warn("Capture result", response.data);
                alert("Payment successful!");
              } catch (error) {
                console.error("Capture Order Error:", error);
                alert("Payment failed!");
              }
            },
          })
          .render("#paypal-buttons-container");

        isRendered.current = true;
      };

      // console.warn("formData", formData);

      if (window.paypal) {
        renderPayPalButtons();
        // alert(renderPayPalButtons());
      } else {
        window.addEventListener("load", renderPayPalButtons);
      }

      return () => {
        window.removeEventListener("load", renderPayPalButtons);
      };
    }, 15000);
  }, [formData, currencyCode]);
  const isFormValid = () => {
    return (
      formData.amount &&
      formData.product_name &&
      formData.quantity &&
      currencyCode
    );
  };

  return (
    <>
      <Navbar expand="lg" className="bg-body-tertiary">
        <Container fluid>
          <Navbar.Brand href="#">
            {" "}
            <img src={logo} className="logo" style={{ height: "50px" }} />
          </Navbar.Brand>
        </Container>
      </Navbar>
      <div className="App  text-center container mt-5 ">
        <Form className="row g-3 d-flex justify-content-center">
          <Form.Group className="mb-2 col-md-7 form_details">
            <select
              id="currencyDropdown"
              className="form-select"
              name="currencyCode"
              disabled
              value={currencyCode}
              onChange={handleChangeCurrency}
            >
              <option value="AUD">Australian Dollar (AUD)</option>
              <option value="BRL">Brazilian Real 2 (BRL)</option>
              <option value="CAD">Canadian Dollar (CAD)</option>
              <option value="CNY">Chinese Renmenbi 4 (CNY)</option>
              <option value="CZK">Czech Koruna (CZK)</option>
              <option value="DKK">Danish Krone (DKK)</option>
              <option value="EUR">Euro (EUR)</option>
              <option value="HKD">Hong Kong Dollar (HKD)</option>
              <option value="HUF">Hungarian Forint 1 (HUF)</option>
              <option value="ILS">Israeli New Shekel (ILS)</option>
              <option value="JPY">Japanese Yen 1 (JPY)</option>
              <option value="MYR">Malaysian Ringgit 3 (MYR)</option>
              <option value="MXN">Mexican Peso (MXN)</option>
              <option value="TWD">New Taiwan Dollar 1 (TWD)</option>
              <option value="NZD">New Zealand Dollar (NZD)</option>
              <option value="NOK">Norwegian Krone (NOK)</option>
              <option value="PHP">Philippine Peso (PHP)</option>
              <option value="PLN">Polish Złoty (PLN)</option>
              <option value="GBP">Pound Sterling (GBP)</option>
              <option value="SGD">Singapore Dollar (SGD)</option>
              <option value="SEK">Swedish Krona (SEK)</option>
              <option value="CHF">Swiss Franc (CHF)</option>
              <option value="THB">Thai Baht (THB)</option>
              <option value="USD">United States Dollar (USD)</option>
            </select>
          </Form.Group>
          <Form.Group className="mb-2 col-md-7 form_details ">
            <Form.Control
              className="form-control_custom"
              type="text"
              id="amount"
              name="amount"
              value={formData.amount}
              disabled
              // onChange={handleChangeAmount}
              placeholder="Enter amount"
            />
          </Form.Group>

          <Form.Group className="mb-2 col-md-7 form_details">
            <Form.Control
              className="form-control_custom"
              type="text"
              id="product_name"
              name="product_name"
              value={formData.product_name}
              disabled
              // onChange={(e) => setFormData({ ...formData, product_name: e.target.value })}
              placeholder="Enter product name"
            />
          </Form.Group>
          <Form.Group className="mb-2 col-md-7 form_details">
            <Form.Control
              className="form-control_custom"
              type="text"
              id="quantity"
              name="quantity"
              value={formData.quantity}
              disabled
              onChange={(e) =>
                setFormData({ ...formData, quantity: e.target.value })
              }
              placeholder="Enter quantity"
            />
          </Form.Group>

          <Form.Group className="mb-2 col-md-7 form_details ">
            <Form.Control
              className="form-control_custom"
              type="hidden"
              id="cur01"
              name="cur01"
              placeholder="Enter cur01"
            />
          </Form.Group>
        </Form>
        {isLoading && (
          <div className="row">
            <div className="col-md-12">
              <div className="loader">
                <div className="loader-logoo">
                  {/* <img src={blackLogoIcon} /> */}
                  <div class="Load-container">
                    <div class="ring"></div>
                    <div class="ring"></div>
                    <div class="ring"></div>
                    <div class="middle">Loading</div>
                  </div>
                </div>
                {/* <Title level={4} className="mt-16">
                Loading...
            </Title> */}
              </div>
            </div>
          </div>
        )}
        <div className="d-flex justify-content-center">
          <div
            id="paypal-buttons-container"
            disabled={!isFormValid}
            className="col-md-7 form_details d-flex justify-content-center"
          ></div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Order Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {modalData ? (
            <pre>{JSON.stringify(modalData, null, 2)}</pre>
          ) : (
            "Loading..."
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {/* <Footer/> */}
      <Footer className="app-Footer">
        <Row gutter={[16][16]} justify={"space-between"} align={"middle"}>
          <Col xs={12} md={8}>
            <div className="footer_link">
              <img src={flogo} height={16} alt="wallet" className="wallet" />
              <NavLink className="ml-8" to={"https://mtmpay.in"}>
                https://mtmpay.in
              </NavLink>
            </div>
          </Col>
          <Col xs={12} md={8}>
            <div className="footer_link">
              <MailOutlined />
              <NavLink className="ml-8" to={"mailto:support@mtmpay.in"}>
                support@mtmpay.in
              </NavLink>
            </div>
          </Col>
          <Col xs={12} md={8}>
            <div className="footer_link">
              <PhoneOutlined rotate={90} />
              <NavLink className="ml-8" to={"tel:7587404000"}>
                +91-7587404000
              </NavLink>
            </div>
          </Col>
        </Row>
      </Footer>
    </>
  );
};
export default App;
